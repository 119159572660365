<template>
  <div class="container--full-centered">
    <div class="logo">
      [luona]
    </div>
    <div class="subline">
      development
    </div>
    <div class="contact">
      <a href="mailto:contact@luona.dev">contact us</a>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  created() {
    document.title = "luona"
  }
}
</script>
<style scoped lang="scss">
.logo {
  font-size: 14vw;
  font-family: 'Abel', sans-serif;
}
.subline {
  font-size: 6vw;
}
.contact {
  a {
    color: crimson;
    text-decoration: none;
  }
  margin-top: 3vw;
  font-size: 3vw;
}
</style>
