<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
  </div> -->
  <router-view/>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Abel&family=Roboto&display=swap');

@for $i from 1 through 6 {
  h#{$i} {
    font-family: 'Abel', sans-serif;
  }
}

#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.container--full-centered {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
</style>
